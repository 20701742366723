import { Settings } from '@/Settings'
import { dromoFields } from './fields'

class DromoUploadCatalogHelper {
    constructor(imagesInZip) {
        this.imagesInZip = imagesInZip
        this.dromoFields = dromoFields
    }

    settings() {
        return {
            importIdentifier: 'Products',
            developmentMode: Settings.getConfig('IS_DROMO_DEVELOPMENT_MODE') === 'true',
            reviewStep: {
                enableUserTransformations: true,
            },
            webhookUrl: this.webhookUrl,
            backendSyncMode: 'FULL_DATA',
        }
    }

    fields() {
        return this.dromoFields
    }

    validatedImage(row) {
        if (!row) {
            return
        }
        if (!row?.value) {
            return row
        } else if (this.imagesInZip.includes(row.value)) {
            return {
                index: row.index,
                value: row.value,
                info: [{ message: 'Valid filename', level: 'info' }],
            }
        } else if (row.value.startsWith('https://') || row.value.startsWith('http://')) {
            return {
                index: row.index,
                value: row.value,
                info: [{ message: 'URL', level: 'info' }],
            }
        } else {
            return {
                index: row.index,
                value: row.value,
                info: [{ message: 'Invalid filename', level: 'error' }],
            }
        }
    }

    registerHooks(dromo) {
        dromo.registerColumnHook('images_1', values => values?.map(row => this.validatedImage(row)))

        // dromo.registerRowHook(data => {
            // data.row.images_1 = this.validatedImage(data.row.images_1)
        //     return data
        // })
    }
}

export default DromoUploadCatalogHelper
