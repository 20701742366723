export const dromoFields = [
    {
        key: 'Style Code',
        label: 'Style Code',
        type: 'string',
        requireMapping: true,
        validators: [{
            validate: 'required',
        }],
    },
    {
        key: 'Parent ID',
        label: 'Parent ID',
        type: 'string',
        requireMapping: true,
        validators: [{
            validate: 'required',
        }],
    },
    {
        key: 'SKU',
        label: 'SKU',
        type: 'string',
        requireMapping: true,
        validators: [{
            validate: 'required',
        }],
    },
    {
        key: 'Color Family',
        label: 'Color Family',
        type: 'string',
        validators: [{
            errorMessage: 'Please enter a valid color family (only letters are allowed)',
            level: 'error',
            validate: 'regex_match',
            regex: '^[a-zA-Z]+$',
        }],
    },
    {
        key: 'Color',
        label: 'Color',
        type: 'string',
        validators: [{
            validate: 'required',
        }],
    },
    {
        key: 'Size',
        label: 'Size',
        type: 'string',
        // manyToOne: true, : TODO: later
        requireMapping: true,
        validators: [{
            validate: 'required',
        }],
    },
    {
        key: 'Department',
        label: 'Department',
        type: 'string',
        validators: [{
            validate: 'required',
        }],
    },
    {
        key: 'Category',
        label: 'Category',
        type: 'string',
        validators: [{
            validate: 'required',
        }],
    },
    {
        key: 'Sub Category',
        label: 'Sub Category',
        type: 'string',
        validators: [{
            validate: 'required',
        }],
    },
    {
        key: 'Product Name',
        label: 'Product Name',
        type: 'string',
        validators: [{
            validate: 'required',
        }],
    },
    {
        key: 'Product Description',
        label: 'Product Description',
        type: 'string',
    },
    {
        key: 'Material Composition',
        label: 'Material Composition',
        type: 'string',
    },
    {
        key: 'Gender',
        label: 'Gender',
        type: 'string',
        validators: [{
            errorMessage: 'Please enter a valid Gender ()',
            level: 'error',
            validate: 'regex_match',
            regex: '^[a-zA-Z]+$',
        }],
    },
    {
        key: 'Is It a Pack?',
        label: 'Is It a Pack?',
        type: 'select',
        selectOptions: [
            { label: 'Yes', value: 'Yes' },
            { label: 'No', value: 'No' },
        ],
        validators: [{
            validate: 'required',
        }],
        // validators: [{
        //     errorMessage: "Should ne 'Yes' or 'No'",
        //     level: "error",
        //     validate: "regex_match",
        //     regex: "^(Yes|No)$",
        // }],
    },
    {
        key: 'SKU Qty in Pack',
        label: 'SKU Qty in Pack',
        type: 'number',
        validators: [{
            validate: 'require_with_values', fieldValues: { 'Is It a Pack?': 'Yes' },
        }],
    },
    {
        key: 'Min Qty Per Size',
        label: 'Min Qty Per Size',
        type: 'number',
    },
    {
        key: 'Min Qty Per Style',
        label: 'Min Qty Per Style',
        type: 'number',
    },
    {
        key: 'Inventory',
        label: 'Inventory',
        type: 'number',
    },
    {
        key: 'Currency',
        label: 'Currency',
        type: 'string',
        validators: [{
            errorMessage: 'Please enter a valid Currency',
            level: 'error',
            validate: 'regex_match',
            regex: '^[a-zA-Z]+$',
        }],
    },
    {
        key: 'Wholesale Price',
        label: 'Wholesale Price',
        type: 'number',
        validators: [{
            validate: 'required',
        }],
    },
    {
        key: 'Retail Price',
        label: 'Retail Price',
        type: 'number',
        validators: [{
            validate: 'required',
        }],
    },
    {
        key: 'Images/1',
        label: 'Images/1',
        type: 'string',
        validators: [{
            validate: 'required',
        }],
    },
    {
        key: 'Images/2',
        label: 'Images/2',
        type: 'string',
    },
    {
        key: 'Images/3',
        label: 'Images/3',
        type: 'string',
    },
    {
        key: 'Images/4',
        label: 'Images/4',
        type: 'string',
    },
    {
        key: 'Images/5',
        label: 'Images/5',
        type: 'string',
    },
    {
        key: 'Product Order',
        label: 'Product Order',
        type: 'string',
    },
    {
        key: 'Country of Origin',
        label: 'Country of Origin',
        type: 'string',
        // validators: [{
        //     errorMessage: "Please enter a valid Country",
        //     level: "error",
        //     validate: "regex_match",
        //     regex: "^[a-zA-Z]+$",
        // }],
    },
    {
        key: 'HS Code',
        label: 'HS Code',
        type: 'number',
    },
    {
        key: 'Barcode',
        label: 'Barcode',
        type: 'number',
    },
    {
        key: 'Shipping Start Date',
        label: 'Shipping Start Date',
        type: 'string',
        validators: [{
            errorMessage: "Valid Date in Format 'DD-MM-YYYY'",
            level: 'error',
            validate: 'regex_match',
            regex: '^$|^(0[1-9]|[12][0-9]|3[01])-(0[1-9]|1[0-2])-\\d{4}$',
        }],
    },
    {
        key: 'Shipping End Date',
        label: 'Shipping End Date',
        type: 'string',
        validators: [{
            errorMessage: "Valid Date in Format 'DD-MM-YYYY'",
            level: 'error',
            validate: 'regex_match',
            regex: '^$|^(0[1-9]|[12][0-9]|3[01])-(0[1-9]|1[0-2])-\\d{4}$',
        }],
    },
    {
        key: 'Lines',
        label: 'Lines',
        type: 'string',
    },
    {
        key: 'Sub Lines',
        label: 'Sub Lines',
        type: 'string',
    },
    {
        key: 'Groups',
        label: 'Groups',
        type: 'string',
    },
    {
        key: 'Sub Groups',
        label: 'Sub Groups',
        type: 'string',
    },
    {
        key: 'Key Styles',
        label: 'Key Styles',
        type: 'string',
    },
    {
        key: 'Keywords',
        label: 'Keywords',
        type: 'string',
    },
    {
        key: 'Recommended Items',
        label: 'Recommended Items',
        type: 'string',
    },
  ]
